const _ = require('lodash');
const $ = require('jquery');
// const axios = require('axios');
// const FormData = require('formdata-polyfill');
const api = require('./api');

const needsValidationClassName = 'needs-validation';
const wasValidatedClassName = 'was-validated';
const submitSelector = '[type="submit"]';
const invalidClassName = 'is-invalid';

function canBeSubmitted(form) {
    return _.some(form.querySelectorAll(submitSelector), (submit) => !submit.disabled);
}

function toggleSubmit(form, toggle) {
    _.forEach(form.querySelectorAll(submitSelector), (submit) => {
        submit.disabled = !toggle;
    });
}

function enableSubmit(form) {
    toggleSubmit(form, true)
}

function disableSubmit(form) {
    toggleSubmit(form, false)
}

function markError(input) {
    input.classList.add(invalidClassName);
}

function markErrors(errors, form) {
    _.forEach(errors, (input) => {
        if (_.isString(input)) {
            markErrors(form.querySelectorAll(`[name="${input}"]`), form);
        } else {
            markError(input, form);
        }
    });
}

function markResponseErrors(errors, form) {
    markErrors(Object.keys(errors), form);
}

function clearError(input) {
    input.classList.remove(invalidClassName);
}

function clearErrors(form) {
    _.forEach(form.querySelectorAll(`[name].${invalidClassName}`), (input) => {
        clearError(input);
    });
}

async function submit(form, handler) {
    if (!canBeSubmitted(form)) {
        return;
    }

    clearErrors(form);

    if (form.classList.contains(needsValidationClassName)) {
        const validityStatus = form.checkValidity();

        form.classList.add(wasValidatedClassName);

        if (validityStatus === false) {
            return;
        }
    }

    disableSubmit(form);

    const $form = $(form);

    $form.trigger($.Event('form_processing'));

    try {
        const response = await handler();

        $form.trigger($.Event('form_success', {
            responseData: response.data
        }));
    } catch ({ response }) {
        if (response.status === 422) {
            markResponseErrors(response.data.errors, form);

            enableSubmit(form);
        }

        $form.trigger($.Event('form_failure', {
            responseStatus: response.status
        }));
    }/* finally {
        //
    }*/

    $form.trigger($.Event('form_processed'));
}

function defaultHandler(form) {
    return function () {
        // const formData = new FormData(form);
        const formData = $(form).serialize();

        return api[(form.method || 'get').toLowerCase()](form.action, formData);
    };
}

function init(selector, handler) {
    const form = document.querySelector(selector);

    if (!handler) {
        handler = defaultHandler(form);
    }

    $(document).on('submit', selector, (event) => {
        const form = event.currentTarget;

        event.preventDefault();

        submit(form, handler);
    });
}

export { init };
