+function (window, Modernizr, $, Mini, undefined) {

  // https://davidwalsh.name/javascript-debounce-function
  function debounce (func, wait, immediate) {
    var timeout;
    return function() {
      var context = this, args = arguments;
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  function log () {
    if (!_Mini.debug) {
      return;
    }

    if (!console.log) {
      return;
    }

    Array.prototype.slice.call(arguments).forEach(function (message) {
      // This is not a debug, console.log is intentional
      console.log(message);
    });
  }


  var _Mini = Mini || {},
      $window = $(window);

  _Mini.embedded = (Modernizr || {}).embedded || top !== self;

  _Mini.scrollIframe = function(offset, direction) {
    direction = direction || 'top';
    if (offset === 0) {
      offset = -1;
    }

    log('Scroll to ' + offset + ' direction ' + direction);
    if (_Mini.embedded) {
      window.parent.postMessage(JSON.stringify({
        'childOrigin': 'scrollToIframe',
        'actions': [
          {
            'action': 'scrollTo',
            'params': {
              'offset': {
                'top' : offset
              }
            }
          }
        ],
        'callback': true
      }), _Mini.postMessageTargetOrigin);
    }
  }

  _Mini.resizeSelfToContentHeight = function () {
    if (!_Mini.postMessageTargetOrigin) {
      return;
    }

    var height = document.getElementById('actual-content-size').offsetHeight + 'px';

    log('Requesting resize to ' + height);
    window.parent.postMessage(JSON.stringify({
      'childOrigin': 'debouncedResize', // or 'child_origin' ? // is required?
      'actions': [
        {
          'action': 'resize',
          'params': {
            'height': height
          }
        }
      ],
      'callback': true
    }), _Mini.postMessageTargetOrigin);
  };

  _Mini.registerMessageDispatcherListener = function () {
    if (!this.postMessageTargetOrigin) {
      log('Post message origin not set');
      return;
    }

    var self = this;

    $window.on('message', function (event) {
      if (event.originalEvent.origin !== self.postMessageTargetOrigin) {
        log('Discarding message from ' + event.originalEvent.origin + ' (current origin allowed is ' + self.postMessageTargetOrigin + ')');
        return;
      }

      var messageData = JSON.parse(event.originalEvent.data),
          childOrigin = messageData.childOrigin.replace(/(?:^\w|[A-Z]|\b\w)/g, function (char) {
            return char.toUpperCase();
          }),
          callback = 'handle' + childOrigin + 'Message';

      if (self.hasOwnProperty(callback) && Object.prototype.toString.call(self[callback]) === '[object Function]') {
        self[callback].apply(self, [messageData, event]);
      } else {
        log('Discarding message ' + messageData.childOrigin + ' (no ' + (callback || 'callback') + ' registered)');
      }
    })
  };

  _Mini.registerResizeSelfToContentHeightListener = function () {
    var self = this;

    $window.on('load resize', debounce(function (event) {
      self.resizeSelfToContentHeight();
    }, 250));
  };

  _Mini.init = function () {
    if (this.embedded && this.postMessageTargetOrigin) {
      this.registerResizeSelfToContentHeightListener();
      this.resizeSelfToContentHeight();

      this.registerMessageDispatcherListener();
    } else {
      log('Not embedded or post message origin not set');
    }
  };

  window.Mini = _Mini;
}(window, window.Modernizr, window.jQuery, window.Mini);
