/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

require('./bootstrap');

const iframe = require('./utils/iframe');
const filters = require('./used-cars/filters');
const detail = require('./used-cars/detail');

(function() {
    iframe.init();

    if (document.body.hasAttribute('data-ready-scroll')) {
        iframe.scrollToTop();
    }

    filters.init();
    detail.init();
})();
