const $ = require('jquery');
require('../integration/minidigital');

const scrollOffset = 80;

function resize() {
    if (Mini.embedded) {
        Mini.resizeSelfToContentHeight();
    }
}

function scrollToElement(element, offset = scrollOffset) {
    scrollToY(window.pageYOffset + element.getBoundingClientRect().top, offset);
}

function scrollToTop(offset = scrollOffset) {
    scrollToY(0, offset);
}

function scrollToY(y, offset = scrollOffset) {
    if (Mini.embedded) {
        Mini.scrollIframe((y === 0 ? -1 : y) - offset);
    } else {
        window.scrollTo(0, y - offset);
    }
}


function init() {
    if (Mini.embedded) {
        document.documentElement.classList.add('embedded');
    }

    Mini.init();
}

export {
    init,
    resize,
    scrollToElement,
    scrollToTop,
    scrollToY
};
