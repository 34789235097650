const $ = require('jquery');
const Swiper = require('swiper/js/swiper.js');
const YouTubePlayer = require('youtube-player');
const iframe = require('../utils/iframe');
const form = require('../utils/form');
const step = require('../utils/step');
const loading = require('../utils/loading');

require('bootstrap/js/dist/tab');
const dmpApp = window.dmpApp;

Promise; // <-- force polyfill :/

const $document = $(document);
let thumbsGallery;
let mainGallery;
let mainGalleryContainer;
const galleryBreakpoint = window.matchMedia('(min-width: 768px)');

function destroyMobileGallery() {
    if (thumbsGallery instanceof Swiper) {
        const swiperInstance = thumbsGallery;

        thumbsGallery = thumbsGallery.el;

        $(swiperInstance.wrapperEl).off('click.gallery');
        swiperInstance.destroy();
    }
}

function initMobileGallery() {
    thumbsGallery = new Swiper(thumbsGallery, {
        init: false,
        spaceBetween: 5,
        slidesPerView: 3,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
    });

    thumbsGallery.on('slideChange', function() {
        mainGallery.slideTo(this.activeIndex + 1, mainGallery.params.speed, true);
    }).on('init', function() {
        $(thumbsGallery.wrapperEl).on('click.gallery', `.${thumbsGallery.params.slideClass}`, (event) => {
            for (let i = 0; i < thumbsGallery.slides.length; i++) {
                if (thumbsGallery.slides[i] === event.currentTarget) {
                    mainGallery.slideTo(i, mainGallery.params.speed, true);
                    break;
                }
            }
        });
    });

    thumbsGallery.init();
}

function destroyDesktopGallery() {
    setTimeout(() => {
        mainGalleryContainer.classList.remove('gallery-main-container--desktop');
    }, 300);

    mainGalleryContainer.classList.remove('gallery-main-container--open');

    $(thumbsGallery).off('click.gallery');
    $(mainGalleryContainer).off('click.gallery');
}

function initDesktopGallery() {
    $(thumbsGallery).on('click.gallery', '.swiper-slide', (event) => {
        mainGalleryContainer.classList.add('gallery-main-container--open');

        iframe.scrollToElement(mainGalleryContainer);

        let index = 0;
        let node = event.currentTarget;

        while ((node = node.previousElementSibling) !== null) {
            index++;
        }

        mainGallery.slideTo(index, 1, true);
    });

    $(mainGalleryContainer).on('click.gallery', '.gallery-main-close, .gallery-main-backdrop', (event) => {
        iframe.scrollToElement(mainGalleryContainer);

        mainGalleryContainer.classList.remove('gallery-main-container--open');
    });

    mainGalleryContainer.classList.add('gallery-main-container--desktop');
}

function galleryBreakpointChanged(matches) {
    if (matches) {
        destroyMobileGallery();
        initDesktopGallery();
    } else {
        destroyDesktopGallery();
        initMobileGallery();
    }
}

function initGallery(mainSelector, thumbsSelector) {
    thumbsGallery = document.querySelector(thumbsSelector);
    mainGallery = document.querySelector(mainSelector);

    if (mainGallery === null || thumbsGallery === null) {
        return;
    }

    mainGalleryContainer = document.querySelector(`${mainSelector}-container`);

    mainGallery = new Swiper(mainGallery, {
        init: false,
        spaceBetween: 10,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        uniqueNavElements: false,
    });

    galleryBreakpoint.addEventListener('change', (event) => {
        galleryBreakpointChanged(event.matches);
    });

    galleryBreakpointChanged(galleryBreakpoint.matches);

    mainGallery.init();

    mainGallery.on('slideChange', function() {
        if (thumbsGallery instanceof Swiper) {
            thumbsGallery.slideTo(this.activeIndex - 1, thumbsGallery.params.speed, false);
        }
    });
}

const ytMediaSelector = '[data-media-type="youtube-video"]';

function getYtMediaWrap(element) {
    if (element) {
        return element.querySelector(ytMediaSelector);
    }

    return null;
}

function injectVideo(mediaWrap) {
    if (mediaWrap.player) {
        return;
    }

    const playerElement = mediaWrap.querySelector('.player');
    const videoId = playerElement.getAttribute('data-video-id');

    const player = YouTubePlayer(playerElement, {
        videoId,
        playerVars: {
            // autoplay: 0,
            iv_load_policy: 3,
            modestbranding: 1,
            rel: 0,
            showinfo: 0,
        }
    });

    player.on('stateChange', (event) => {
        if (event.data === YT.PlayerState.ENDED) {
            player.seekTo(0);
            // player.pauseVideo();
            player.stopVideo();
        }
    });

    mediaWrap.player = player;
}

function pauseVideo(mediaWrap) {
    if (!mediaWrap.player) {
        return;
    }

    mediaWrap.player.pauseVideo();
    // mediaWrap.player.seekTo(0);

    // mediaWrap.player.stopVideo();
}

function initYtVideo() {
    const firstYtWrap = getYtMediaWrap(document.querySelector('.used-car__media'));

    if (firstYtWrap === null) {
        return;
    }

    if (mainGallery) {
        let mediaWrap = getYtMediaWrap(mainGallery.slides[mainGallery.activeIndex]);

        if (mediaWrap !== null) {
            injectVideo(mediaWrap);
        }

        mainGallery.on('slideChange', function() {
            mediaWrap = getYtMediaWrap(this.slides[this.activeIndex]);

            if (mediaWrap !== null) {
                injectVideo(mediaWrap);
            }

            mediaWrap = getYtMediaWrap(this.slides[this.previousIndex]);

            if (mediaWrap !== null && mediaWrap.player) {
                pauseVideo(mediaWrap);
            }
        });
    } else {
        injectVideo(firstYtWrap);
    }
}

function initTabs() {
    $document.on('shown.bs.tab', '[data-toggle="tab"]', (event) => {
        iframe.scrollToElement(event.currentTarget);
        iframe.resize();
    });
}

function initContactForm(selector, showFormSelector, tabFormSelector) {
    const element = document.querySelector(selector);

    if (element === null) {
        return;
    }

    form.init(selector);

    const stepGroup = step.getGroup(element);

    const tabForm = document.querySelector(tabFormSelector);

    $document.on('click', showFormSelector, (event) => {
        if (tabForm !== null) {
            $(tabForm).tab('show');
        } else {
            iframe.scrollToElement(element);
        }

        // xxx gtag + dmpApp
    });

    $document.on('form_processing', selector, (event) => {
        const processed = new Promise((resolve, reject) => {
            $document.one('form_processed', selector, (event) => {
                resolve();
            });
        });

        loading.onPromise(processed, stepGroup);
    }).on('form_success', selector, (event) => {
        // xxx gtag

        if (dmpApp) {
            dmpApp.dataLayerSet('hmail', event.responseData.hmail);
            dmpApp.dilSubmitData().adformTrack();
            dmpApp.dataLayerUnset('hmail');
        }

        step.change(stepGroup, 'success');

        iframe.scrollToElement(stepGroup);
        iframe.resize();
    }).on('form_failure', selector, (event) => {
        const status = event.responseStatus;

        if (status === 422) {
            // Do nothing, already handled
        } else if (status === 419) {
            // Page expired / CSRF token error -- reload the page and try again
            step.change(stepGroup, 'error_reload');
        } else {
            // Generic error -- try again later
            step.change(stepGroup, 'error');
        }

        iframe.scrollToElement(stepGroup);
        iframe.resize();
    });
}

function init() {
    initGallery('.gallery-main', '.gallery-thumbs');

    initYtVideo();

    initTabs();

    initContactForm('#contact-form', '.contact-form-btn', '#contact-tab');
}

export { init };
